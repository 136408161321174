<template>
  <div>
    <v-card flat>
        <v-card-actions v-if="debug" style="color: red; font-size: 0.8em;">
            Question ID: {{ question.id }}
        </v-card-actions>
        <v-card-title style="word-break: normal;">
        {{ question.body.text[language] }}
        </v-card-title>
        <v-card-subtitle style="word-break: normal;">
        {{ question.subtext.text[language] }}
        </v-card-subtitle>

        <v-card-text>
            <div v-for="group in question.groups" :key="`qid-${question.id}-gid-${group.id}`">
                <div class="group-text" v-if="group.text[language]" v-html="group.text[language]"/>

                <template v-if="group.type=='radio'">
                    <v-radio-group :required="question.display===true && question.required===true" :rules="question.controller.validate" v-model="answers[group.id].value">
                        <div v-for="choice in group.choices" :key="`qid-${question.id}-gid-${group.id}-cid-${choice.id}`">
                            <v-radio :value="choice.value" style="margin-bottom: 15px;">
                                <template v-slot:label>
                                    <span v-html="choice.text[language]"/>
                                </template>
                            </v-radio>
                            <v-text-field v-if="displayOther(choice, answers[group.id])" v-model="answers[group.id].other[choice.id]" label="Please specifiy"></v-text-field>                                
                        </div>
                    </v-radio-group>
                </template>

                <template v-else-if="group.type=='checkbox'">
                    <div v-for="choice in group.choices" :key="`qid-${question.id}-gid-${group.id}-cid-${choice.id}`">
                        <v-checkbox v-model="answers[group.id].value" :rules="question.controller.validate" :value="choice.value" style="margin-bottom: -15px;">
                            <template v-slot:label>
                                <span v-html="choice.text[language]"/>
                            </template>
                        </v-checkbox>
                        <v-text-field v-if="displayOther(choice, answers[group.id])" v-model="answers[group.id].other[choice.id]" label="Please specifiy"></v-text-field>
                    </div>
                </template>

            </div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
    props: {
        position: {
            type: Number,
            required: false
        },
        question: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        },
        logicMap: {
            type: Object,
            required: true
        }
    },
    methods: {
        displayOther(choice, answer){
            if(choice.other){
                if( (answer.value!=null && typeof answer.value=='object' && answer.value.includes(choice.value)) || (choice.value==answer.value)){
                    return true
                }
                
            }
            answer.other[choice.id] = null
            return false
        }
    },
    computed: {
        answers(){
            return this.$attrs.value
        },
        debug(){
            return this.$store.getters.debug
        }
    },
    watch: {
        answers: {
            deep: true,
            handler(){
                this.question.controller.handler()
            }
        }
    }
}
</script>

<style scoped>
.group-text{
    font-weight: bold;
}
</style>