<template>
<div style="width: 100%; min-height: 100px; background-color: white; position: relative; margin-bottom: -50px;">

  <div style="position: absolute; display: inline-block; width: 10%; min-width: 80px;">
    <div style="display: inline-block; height:100px; width:30px; background-color: #261062;" @click="toggleDebug"/>
    <div style="display: inline-block; height:100px; width:10px; background-color: white;"/>
    <div style="display: inline-block; height:100px; width:10px;" :class="['defaultAccent',$route.meta.accentClass]"/>
  </div>

  <div style="display: inline-block; text-align: center; width: 100%; height: 100px;" @click="$router.push({name:'Home'})">        
    <img
      alt="CUA"
      class="shrink mt-1 hidden-sm-and-down cua_logo"
      contain
      min-width="100"
      src="https://cuabladdercancerdecisiontool.ca/img/cua_logo.41628902.jpg"
      width="700"
      />
      <!-- :src="images.cua.logo" -->
      <!-- style="transform: translateX(-10%);" -->
    
    <v-img
      alt="CUA"
      class="shrink mt-1 visible-sm-and-down hidden-md-and-up cua_logo"
      contain
      min-width="90"
      :src="images.cua.logo_crest"
      width="90"
      style="position: relative; left: 50%; transform: translateX(-50%);"
      />
      <!-- style="margin: 0 auto;transform: translateX(-10%);" -->
      <div style="text-align: right; position: relative; top: -100%;">
        <LanguageSelect v-if="!summary" style="display: inline-block; width: 100px;"/>
      </div>
  </div>  
</div>
</template>
<script>
import cua_logo from '@/assets/logos/cua_logo.jpg'
import cua_logo_crest from '@/assets/logos/logo_cua_crest.png'
import LanguageSelect from '@/components/LanguageSelect'
export default {
    props: {
      summary: {
        type: Boolean,
        required: false,
        default: false
      }

    },
    components: {
      LanguageSelect
    },
    data(){
      return {
          delay: 500,
          clicks: 0,
          timer: null
      }
    },     
     methods: {
        dblClick() {
          let self = this
          clearTimeout(this.timer)
          self.clicks++
          self.timer = setTimeout(() => {
            self.clicks = 0
          }, self.delay);
          
          return self.clicks==2
        },
        toggleDebug(){
          if(this.dblClick()){
            this.$store.commit('debug',!this.$store.getters.debug)
          }
        }
     },
    computed: {
        images: function(){
            return {
                cua: {
                    logo: cua_logo,
                    logo_crest: cua_logo_crest
                }
            }
        },        
    }
}
</script>

<style>

</style>