<template>
<div>
    <v-dialog v-model="DisplayBlocker">
      <v-card>
        <v-card-title>Site Under Development</v-card-title>
        <v-card-subtitle>Please check with administrator for access</v-card-subtitle>
        <v-card-text>
          <v-text-field type="password" v-model="password" label="Password"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>    
</div>  
</template>

<script>
export default {
    created(){
        let saved = this.cache.storage.local.get(this.config.key)
        if(saved){
            this.password = saved.password
        }
    },
    data(){
        return {
            config: {
                key: 'cua_bc_blocker',
                password: 'cua_BC_2024'
            },
            password: null
        }
    },
    computed: {
        Allow(){
            return this.$attrs.value
        },
        DisplayBlocker(){
            return !this.Allow
        }
    },
    watch: {
        password(){
            if(this.password==this.config.password){
                this.cache.storage.local.set(this.config.key, {password: this.password})
                this.$emit('input',true)
            }else{
                this.$emit('input',false)
            }
        }
    }
}
</script>

<style>

</style>