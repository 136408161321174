<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-container fliud>
            <div v-for="(question, index) in questionSet.questions" :key="`qid-${question.id}`">
              <v-row v-show="question.display">
                <v-col>
                  <Question :position="index+1" v-model="question.answers" :logicMap="logicMap" :question="question" :language="language"/>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-col>
        <!-- <v-col>
          answers
          <json-viewer :value="answers"/>
        </v-col>
        <v-col>
          <json-viewer :value="logicMap"/>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Question from './question'
  import Logics from './logics'
  let questionController = function(question, component){
    let debug = false
    let answers = {}
    function reset(){
      question.valid = false
      for(let g=0; g<question.groups.length; g++){
        let group = question.groups[g]
        answers[group.id] = {
          value: group.type=='checkbox' ? [] : null,
          other: {}
        }

        for(let c=0; c<group.choices.length; c++){
          let choice = group.choices[c]
          answers[group.id].other[choice.id] = null
        }
  

      }
    }    


    reset()
    component.$set(question, 'valid', false)
    component.$set(component.answers, question.id, answers)

    this.status = ()=>{
      console.log('status', {question, answers, logics: {
        displayLogic: question.displayLogic
      }})
    }

    this.display = (bool)=>{
      question.display = bool
    }

    this.valid = (bool)=>{
      question.valid = bool
    }

    this.validate = [
      (v) => {
        let validationResult = false
        if(!question.display){          
          validationResult = true
        }else{
          let validationLogic = question.validationLogic
          let required = question.required
          let noLogics = true
          for(let requirement in validationLogic){
            if(validationLogic[requirement].length>0){
              noLogics = false
              break
            }
          }
          if(required && noLogics){
            validationResult = v!=null && v.length>0
          }
          if(required && !noLogics){
            validationResult = runLogic(validationLogic)
          }
  
          question.valid = validationResult
        }

        return validationResult
      }
    ]

    //Actually checking logic results
    function runLogic(logic){
      let logicMap = component.logicMap
      let result = {}
      let noRules = true
      for(let requirement in logic){
        result[requirement] = null
        let rules = logic[requirement]

        if(rules.length>0){
          noRules = false
          if(requirement=='allTrue'){
            result[requirement] = true
            for(let i=0; i<rules.length; i++){
              let ruleId = rules[i]
              let logicResult = logicMap[ruleId].result
              if(debug){
                console.log({
                  ruleId, logicResult, result
                })
              }
              if(logicResult==null){
                result[requirement] = null
                break;
              }
              if(logicResult===false){
                result[requirement] = false
                break;
              }
            }
  
          }
  
          if(requirement=='anyTrue'){
            result[requirement] = false
            for(let i=0; i<rules.length; i++){
              let ruleId = rules[i]
              let logicResult = logicMap[ruleId].result
              if(debug){
                console.log({
                  ruleId, logicResult, result
                })
              }
              if(logicResult==null){
                result[requirement] = null
                break;
              }
              if(logicResult===true){
                result[requirement] = true
                break;
              }
            }
  
          }
          
          if(requirement=='allFalse'){
            result[requirement] = true
            for(let i=0; i<rules.length; i++){
              let ruleId = rules[i]
              let logicResult = logicMap[ruleId].result
              if(debug){
                console.log({
                  ruleId, logicResult, result
                })
              }
              if(logicResult==null){
                result[requirement] = null
                break;
              }
              if(logicResult===true){
                result[requirement] = false
                break;
              }
            }
  
          }
  
          if(requirement=='anyFalse'){
            result[requirement] = false
            for(let i=0; i<rules.length; i++){
              let ruleId = rules[i]
              let logicResult = logicMap[ruleId].result
              if(debug){
                console.log({
                  ruleId, logicResult, result
                })
              }
              if(logicResult==null){
                result[requirement] = null
                break;
              }
              if(logicResult===false){
                result[requirement] = true
                break;
              }
            }

          }
        }

        // let pass = true
        //   for(let r in result){
        //     if(result[r]==null && r=='allTrue'){
        //       pass = null
        //     }
        //     if(result[r]===false){
        //       pass = false
        //     }
        //   }

        //   if(debug){
        //     console.log({
        //       question,
        //       logic,
        //       pass,
        //       result
        //     })
        //   }
        //   return pass        
      }

      if(noRules){
        return undefined
      }

      let pass = false
      let allNull = true
      for(let r in result){
        if(result[r]!=null){
          allNull = false
        }
        if(result[r]===true){
          pass = true
        }
      }

      return allNull ? null : pass
    }
    this.process = function(){
      if(debug){console.group('process logic')}
      
      let displayResult = runLogic(question.displayLogic)
      if(debug){console.log({displayResult})}
      if(displayResult!==undefined){
        if(question.display && !displayResult){
          reset()
        }
        question.display = displayResult
      }


      let validationResult = runLogic(question.validationLogic)
      if(debug){console.log({validationResult})}
      if(validationResult!==undefined){
        question.valid = validationResult
      }


      if(debug){console.groupEnd()}
    }

    this.handler = function(){
      
      for(let gid in answers){
        let group = question.groups.find((group)=>{return group.id==gid})
        if(group.type=='checkbox' && answers[gid].value.length>0){
          let values = answers[gid].value
          let lastValue = values[values.length-1]
          let choice = group.choices.find((choice)=>{return choice.value==lastValue})

          /* Handle exclusive */
          if(choice.exclusive && values.length>1){
            answers[gid].value = [lastValue]
          }else if(values.length>1){
            for(let v=0; v<values.length; v++){
              let value = values[v]
              let choice = group.choices.find((choice)=>{return choice.value==value})
              if(choice.exclusive){              
                answers[gid].value.splice(v,1)
              }
            }
          }
        }
      }
    }

    this.reset = reset

    
    this.debug = function(){
      debug = !debug
    }
  }

  let logicController = function(logic, component){
    let debug = false
    let results = {}

    function init(){
      for(let requirement in logic.rules){
        let rules = logic.rules[requirement]
        results[requirement] = {}

        for(let i=0; i<rules.length; i++){
          let rule = rules[i]
          results[requirement][rule.id] = false
        }
      }
      checkResults(results)
    }

    function objLength(obj){
      let counter = 0
      for(let i in obj){
        counter++
      }
      return counter
    }
    init()
    component.$set(logic, 'results', results)

    //Determine results of individual rules
    function process(answers){
      let operators = ['==','!=','>','>=','<','<=']
      let makeNumber = ['>','>=','<','<=']
      let requirements = logic.rules
      if(debug){console.group('Process',logic)}
      for(let requirement in requirements){
        let rules = requirements[requirement]
        if(debug){console.log({requirement, rules})}
        for(let i=0; i<rules.length; i++){
          let rule = rules[i]
          let answer = answers[rule.inputSource.qid]
          let question = component.questionMap[rule.inputSource.qid]
          if(rule.inputSource.gid){
            answer = answers[rule.inputSource.qid][rule.inputSource.gid]
          }

          if(answer.value!=null && answer.value.length>0){
            
            let tests = rule.value
            if(debug){console.log({requirement, tests, answer_value: answer.value})}
            let testResult
            for(let t=0; t<tests.length; t++){
              let test = tests[t]
              if(operators.includes(rule.operator)){
                let testString
                if(makeNumber.includes(rule.operator)){
                  testString = `${parseFloat(answer.value,10)} ${rule.operator} ${parseFloat(test,10)}`
                }else{
                  if(typeof answer.value=='object'){
                    if(rule.operator=='=='){
                      testResult = answer.value.includes(test)  
                    }
                    if(rule.operator=='!='){
                      testResult = !answer.value.includes(test)
                    }    
                  }else{
                    testString = `'${answer.value}' ${rule.operator} '${test}'`
                  }
                }
                if(testString!=undefined){
                  testResult = eval(testString)
                }
                if(debug){
                  console.log({testResult, operator: rule.operator, test, answerValue: answer.value, qid: rule.inputSource.qid, gid: rule.inputSource.gid})   
                }

                results[requirement][rule.id] = testResult
                if(testResult && ['allTrue','allFalse']){
                  break;
                }
                // if(requirement=='allTrue' && testResult===false){
                //   break;
                // }
                // if(requirement=='anyTrue' && testResult===true){
                //   break;
                // }
                // if(requirement=='noneTrue' && testResult===true){
                //   break;
                // }
                // if(debug){console.log({results, testString, testResult})}
              }
            }
            
            if(debug){console.log({testResult, results})}

          }else{
            results[requirement][rule.id] = null
            if(debug){console.log('no answer')}
          }

        }
      }
      if(debug){console.groupEnd()}

      checkResults(results)
    }

    function checkResults(results){
      if(debug){console.group('checkResults',{id: logic.id, results, debug})}
      let result = {}
      for(let requirement in results){
        result[requirement] = null
        let records = results[requirement]

        if(requirement=='allTrue' && objLength(records)>0){
          result[requirement] = true
          for(let id in records){
            let record = records[id]
            if(record==null){
              if(debug){
                console.log('null',{record, id})
              }
              result[requirement] = null
              break;
            }else{
              if(record!=true){
                result[requirement] = false
                if(debug){console.log(`${requirement} - failed`, {id, record})}
                break;
              }
            }
          }
        }

        if(requirement=='anyTrue' && objLength(records)>0){
          result[requirement] = false
          for(let id in records){
            let record = records[id]
            if(record===true){
              result[requirement] = true
              if(debug){console.log(`${requirement} - failed`, {id, record})}
              break;
            }
          }
        }

        if(requirement=='allFalse' && objLength(records)>0){
          result[requirement] = true
          for(let id in records){
            let record = records[id]
            if(record==null){
              result[requirement] = null
              break;
            }
            if(record===true){
              result[requirement] = false
              if(debug){console.log(`${requirement} - failed`, {id, record})}
              break;
            }
          }
        }
      }

      if(debug){console.log({result})}
      if(debug){console.groupEnd()}
      if(component.logicMap[logic.id]){
        let pass = false
        let allNull = true
        for(let r in result){
          if(result[r]!=null){
            allNull = false
          }
          if(result[r]===true){
            pass = true
          }
        }
        component.logicMap[logic.id].result = allNull ? null : pass
        if(debug){
          console.log({
            result, allNull
          })
        }
      }
      return result
    }

    this.process = function(answers){
      process(answers)
    }

    
    this.debug = function(){
      debug = !debug
    }
  }

export default {
  name: "QuestionSet",
  props:{
    questionSet:{
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    }
  },
  components: {
    Question,
    Logics
  },
  created(){
    let questionSet = this.questionSet
    for(let i=0; i<questionSet.questions.length; i++){
      let question = questionSet.questions[i]
      question.controller = new questionController(question, this)
      question.answers = this.answers[question.id]      
      this.$set(this.questionMap, question.id, question)
    }

    for(let i=0; i<questionSet.logics.length; i++){
      let logic = questionSet.logics[i]
      logic.controller = new logicController(logic, this)
      this.$set(logic,'result',null)
      this.$set(this.logicMap,logic.id, logic)
    }

    this.$emit('registerController', {
      questionMap: this.questionMap,
      logicMap: this.logicMap
    })
  },
  data(){
    return {
      questionMap: {},
      logicMap: {}
    }
  },
  computed:{
    answers(){
      return this.$attrs.value
    }
  },
  watch: {
    answers: {
      deep: true,
      handler(after, before){
        let logicMap = this.logicMap
        for(let id in logicMap){
          let logic = logicMap[id]
          logic.controller.process(this.answers)
        }


        let questionSet = this.questionSet
        for(let i=0; i<questionSet.questions.length; i++){
          let question = questionSet.questions[i]
          // console.log({question, logicMap})
          question.controller.process(logicMap)
        }
      }
    }
  }
}
</script>

<style>

</style>