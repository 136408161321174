<template>
  <div>
    <v-select v-model="language" :items="Languages" @change="handleChange"></v-select>
  </div>
</template>

<script>
export default {
    created(){
        this.init()
    },
    data(){
        return {
            language: null
        }
    },
    methods: {
        init(){
            if(this.Controller && this.Controller.language){
                this.language = this.Controller.language
            }
        },
        handleChange(){
            let language = this.language
            this.Controller.setLanguage(language)
        }
    },
    computed: {
        Controller(){
            return this.$store.getters.controller.Language
        },
        Languages(){
            return this.Controller.languages
        },
        _language(){
            return this.Controller.language
        }
    },
    watch:{
        _language:{
            deep: false,
            handler(after, before){
                if(after != before){
                    this.init()
                }
            }
        }
    }
}
</script>

<style>

</style>