<template>
    <div>
        <!-- <json-viewer :value="items"/> -->
        <div v-if="items.length==1" v-html="items[0][language]"/>
        <template v-else>
            <ul v-for="(item, index) in items" :key="`item-${index}`">            
              <List v-if="(typeof item=='object' && item.length!=undefined)" :items="item" :language="language"/>
              <li v-else>
                  <span v-html="item[language]"/>
              </li>
          </ul>        
      </template>
    </div>
  </template>
  
  <script>
  import List from './list'
  export default {
      name: "List",
      props: {
          items: {
              // type: Array,
              required: false
          },
          language: {
              type: String,
              required: true
          }
      },
      components: {
          List
      }
  }
  </script>
  
  <style lang="scss" scoped>
  ul{
      width: 95%;
  }
  li{
      list-style-type: none;
  }
  li:before{
    content: "";
    border-color: transparent $accent;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1.2em;
    top: 1.2em;
    position: relative;
  }
  </style>