const bc = {
    ktContent: require('./ktContent/bc.json'),
    questionSet: require('./questionSets/bc.json')
}

const mCRPC = {
    ktContent: require('./ktContent/mCRPC.json'),
    questionSet: require('./questionSets/mCRPC.json')
}

export default{
  mCRPC,
  bc
}