<template>
    <div>
        
        
        <v-container>
            <v-row>
                <v-col>
                <!-- :paginate-elements-by-height="1400" -->
                <!-- <vue-html2pdf
                    :show-layout="true"
                    :float-layout="false"
                    :enable-download="true"
                    :preview-modal="true"
                    :filename="`summary-${new Date().toISOString().substr(0,10)}`"
                    :pdf-quality="2"
                    :manual-pagination="true"
                    :html-to-pdf-options="htmlToPdfOptions"
                    pdf-format="letter"
                    pdf-orientation="portrait"
                    pdf-content-width="100%"

                    @progress="onProgress"
                    @hasStartedGeneration="hasStartedGeneration"
                    @hasGenerated="hasGenerated"
                    @hasDownloaded="hasDownloaded"
                    ref="html2Pdf"
                    > -->
                    <section slot="pdf-content" id="pdf_html">
                        <Appbar v-if="pdfState=='generating'" :summary="true"/>

                        <div>
                            <AnswerSummary :language="language" :questions="questionSet.questions" :answers="answers" style="margin-bottom: 50px;"/>
                            <h2>{{$store.getters.localization['recommendations'][language]}}:</h2>
                            <Recommendations class="pdf-content" :summary="true" :language="language" :recommendations="recommendations" :questionSet="questionSet" :kt="kt" :answers="answers" @treatmentSelection="treatmentSelection" @done="generatePDF"/>
                        </div>

                        <div v-html="CSS"/>
                    </section>
                <!-- </vue-html2pdf> -->
                </v-col>
            </v-row>
        </v-container>

        <center style="margin-top: 25px;">
            <v-btn v-if="!pdfLink.url" color="primary" :disabled="busy" @click="generatePDF">
                <template v-if="!busy">
                    Make PDF
                </template>
                <v-icon v-else class="mdi-spin">mdi-loading</v-icon>
            </v-btn>
            <v-btn v-else :href="pdfLink.url" color="primary" target="_blank">
                {{$store.getters.localization['Download'][language]}}
            </v-btn>
        </center>

        <center style="margin-top: 25px;">
            <v-btn color="primary" @click="copy">
                {{$store.getters.localization['Copy Link'][language]}}
            </v-btn>
        </center>
        
        
        <v-snackbar v-model="snackbar">
            <v-progress-linear :value="progress"></v-progress-linear>
        </v-snackbar>
    </div>
</template>
  
<script>
import VueHtml2pdf from 'vue-html2pdf'
import Recommendations from './Recommendations'
import AnswerSummary from './lib/answer-summary.vue'
import Appbar from '@/components/Appbar'
import axios from 'axios'

export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        questionSet: {
            type: Object,
            required: true
        },
        answers: {
            type: Object,
            required: true
        },
        recommendations: {
            type: Object,
            required: true
        },
        kt: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components: {
        VueHtml2pdf,
        Recommendations,
        Appbar,
        AnswerSummary
    },
    data(){
        return {
            busy: false,
            summary: false,
            progress: 0,
            pdfState: 'idle',
            timer: null,
            snackbar: false,
            pdfServer: 'https://api.vivomap.ca',
            pdfLink: {}
        }
    },
    methods: {
        copy(){
            // let data = `<a href="${window.location.href}">${window.location.href}</a>`
            // let selBox = document.createElement('textarea');
            // selBox.setAttribute("id", "link-copy");
            // selBox.style.position = 'fixed';
            // selBox.style.left = '0';
            // selBox.style.top = '0';
            // selBox.style.opacity = '0';
            // selBox.value = data;
            // document.body.appendChild(selBox);
            // selBox.focus();
            // selBox.select();
            // navigator.clipboard.writeText(selBox.value);
            // document.body.removeChild(selBox);
            navigator.clipboard.writeText(window.location.href)
            setTimeout(() => {
                alert('Link to share via email has been copied to the clipboard.') 
            }, 800);
        },
        download(){
            clearTimeout(this.timer)
            let self = this
            this.snackbar = true
            this.pdfState = 'starting'
            this.timer = setTimeout(() => {
                self.generateReport()
            }, 3000);
        },
        async generatePDF(){
            let self = this
            self.pdfState='generating'
            self.busy = true
            
            setTimeout(() => {
                let html = document.getElementById('pdf_html').innerHTML //.getInnerHTML()
                self.pdfState='idle'
                self.sendRequest('post',self.pdfServer+'/pdf/make/cua',{
                    html
                },{remove:['CUA-API-DRUG']}).then(function(response){
                    self.pdfLink = response.data
                    self.busy = false
                    // window.open(self.pdfLink.url,'Summary')
                })            
            }, 800);
        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(e){
            this.progress = e
        },
        hasStartedGeneration(){
            this.pdfState = 'hasStartedGeneration'
        },
        hasGenerated(){
            let self = this
            self.snackbar = false
            self.pdfState = 'idle'
        },
        hasDownloaded(){
            let self = this
            self.snackbar = false
            self.pdfState = 'idle'
        },
        treatmentSelection(data){
            this.$emit('input',data)
        }
    },
    computed: {
        CSS(){
            return `
            <style type="text/css">
            .defaultAccent{
                background-color: #E9212D;
            }
            .bc{
                background-color: #FAA83E;
            }
                .mCRPC{
                background-color: #006EB5;
                }

            .pdf-content{
                font-family: "Roboto", sans-serif;
                font-size: 10pt;
            }

            .summary-answer:nth-child(odd){
                background-color: whitesmoke;
            }


            /*KT*/
            .heading-icon{
                width: 35px;
            }
            .treatment-title{
                color: #261062;
                text-align: center;
            }
            .treatment-heading{
                color: #E9212D;
            }

            /*KT-Links*/
            li{
                list-style-type: none;
            }
            li:before{
                content: "";
                border-color: transparent #E9212D;
                border-style: solid;
                border-width: 0.35em 0 0.35em 0.45em;
                display: block;
                height: 0;
                width: 0;
                left: -1.2em;
                top: 1.2em;
                position: relative;
            }

            /*KT-lists*/
            ul{
                width: 95%;
            }
            li{
                list-style-type: none;
            }
            li:before{
                content: "";
                border-color: transparent #E9212D;
                border-style: solid;
                border-width: 0.35em 0 0.35em 0.45em;
                display: block;
                height: 0;
                width: 0;
                left: -1.2em;
                top: 1.2em;
                position: relative;
            }


            /*Recommendations*/
            .recommendation-title{
                color: #261062;
                text-align: center;
            }
            .recommendation-heading{
                color: #02C5F3;
                font-weight: bold;
            }
            .recommendation-row{
                margin-top: 25px;
                border-bottom: 1px solid lightgray;
            }
            .province-name{
                font-weight: bold;
                color: white;
                background-color: #261062;
                padding: 5px 5px 5px 15px;
            }
            .province-funding{
                font-weight: bold;
            }
            .province-formularies{
                font-weight: bold;
            }
            .drug-row td{
                vertical-align: top;
            }

            .v-icon.default{
                color: rgb(128, 128, 128);
            }
            .v-icon.active{
                color: #00A8CB;
            }

            .button{
                background-color: #261062 !important;    
                color: white;
            }


            tbody {
            tr:hover {
                background-color: transparent !important;
            }
            }
            .v-data-table-header{
                background-color: #261062 !important;
            }

            .v-data-table-header th{
                padding-top: 15px;
                color: white !important;
                background-color: #261062 !important;
                font-weight: bold;
                border-top: 0px;
                border-left: 0px;
                border-right: 1px;
                border-bottom: 0px;
                border-color: whitesmoke;
                border-style: solid;
            }

            tr.drug-row td{
                padding-top: 15px !important;
                border-top: 0px;
                border-left: 0px;
                border-right: 1px;
                border-bottom: 1px;
                border-color: whitesmoke;
                border-style: solid;    
            }
            tr.drug-row td p{
                font-size: 0.8rem;
            }

            .recommendations-view .v-label.theme--light{
                color: #02C5F3;
                font-weight: bold;
            }

            .drugTable th, .drugTable tbody *{
                font-size: 9pt;
            }
            
            .drugTable table tbody tr *{
                font-size: 8pt !important;
            }
            
            @media print {
                .pageBlock {
                    break-inside: avoid;
                }
            }
            </style>
            `
        },
        htmlToPdfOptions: function(){
          return {
            enableLinks: true,
            /*
            html2canvas: {
                scale: 1,
                width: 890,
                height: 3000*this.KT_selection.length
            },
             * 
             */
          }
        },
    }
}
</script>

<style scoped>
.pdf-content{
    font-family: "Roboto", sans-serif;
    font-size: 10pt;
}
</style>