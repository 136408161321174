import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import JsonViewer from "vue-json-viewer"
import cache from "./plugins/cache.js"


Vue.config.productionTip = false

Vue.use(JsonViewer)

let code = new Vue({
  created: function(){
    Vue.prototype.cache = cache
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

if(location.hostname=="localhost"){
  window.code = code;
}