<template>
    <div>
      <ul v-for="(item, index) in items" :key="`item-${index}`">
          <li v-html="item[language]">
          </li>
          <!-- <a :href="links[item][language].link" v-html="links[item][language].text"/> -->
      </ul>
    </div>
  </template>
  
  <script>
  export default {
      name: "Links",
      props: {
          items: {
              type: Array,
              required: false
          },
          links: {
            type: Object,
            required: true
          },
          language: {
            type: String,
            required: true
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
    li{
        list-style-type: none;
    }
    li:before{
    content: "";
    border-color: transparent $accent;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1.2em;
    top: 1.2em;
    position: relative;
    }
  </style>