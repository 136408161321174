<template>
    <div>
        <div style="text-align: center; margin-bottom: 40px;">
            <h2>
                {{ $store.getters.localization['Decision Tool Summary'][language] }}
            </h2>
            <div class="theme-primary">
                <strong>
                    {{ $store.getters.localization['Decision Tool Summary.below'][language] }}
                </strong>
            </div>   
        </div>
        <v-container style="font-size: 0.8em;">
            <template v-for="question in questions">
                <v-row v-if="question.display" :key="`summary-question-${question.id}`" class="summary-answer">
                    <v-col>
                        <strong>
                            <span v-html="question.body.text[language]"/>
                        </strong>
                    </v-col>
                    
                    <v-col cols="5">
                        <span v-html="getAnswers(question).toString()"/>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        questions: {
            type: Array,
            required: true
        },
        answers: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    methods: {
        getAnswers(question){
            let language = this.language
            let output = []
            let answers = this.answers[question.id]
            for(let gid in answers){
                let record = answers[gid]
                let choiceMap = {}
                let group = question.groups.find((item)=>{return item.id==gid})
                for (let index = 0; index < group.choices.length; index++) {
                    let choice = group.choices[index]
                    choiceMap[choice.value] = choice
                    
                }
                
                if(typeof record.value=='string'){
                    let answer = choiceMap[record.value].text[language]
                    output.push(answer)
                }
                if(typeof record.value=='object'){
                    for(let i=0; i<record.value.length; i++){
                        let value = record.value[i]
                        let answer = choiceMap[value].text[language]
                        output.push(answer)
                    }
                }
            }
            
            return output
        }
    }
}
</script>

<style>
.summary-answer:nth-child(odd){
    background-color: whitesmoke;
}
</style>