<template>
  <div>
    <v-container id="decision_tool">
      <v-row>
        <v-col style="text-align: center;">
          <h1 style="font-weight: normal;" v-html="$store.getters.localization.toolTitle[_language]"/>
        </v-col>
      </v-row>
      <v-row style="padding: 0px; margin: 15px 0px 0px 0px;">
        <v-col align="center" style="padding:0px;">
        </v-col>
      </v-row>
  
      <v-row>
        <v-col style="padding:0px;">
          <v-progress-linear
            background-color="none"
            color=""
            :value="_progress"
            height="8"
          />
            <v-container style="max-width: 800px; padding: 0px 0px 35px 0px;" data-test-tabs>
                <v-row>
                    <v-col cols="12" md="4" align="center" v-for="(step, index) in steps" :key="`tab-${step.name}`">
                        <button data-test-tab="survey" @click="selectStep(step.name)" :disabled="index>0 ? !steps[index-1].complete : false" :class="['step-tab', {'active':tab==step.name}, {'disabled':index>0 ? !steps[index-1].complete : false}, {'complete':step.complete && (step.name!='summary' || step.name==tab)}, $route.meta.accentClass]">
                            <div class="step-tab-content">
                                <v-icon style="display:block; color: white;">
                                  {{step.icon}}
                                </v-icon>
                                {{$store.getters.localization[step.name][_language]}}
                            </div>
                            <div class="step-tab-carrot"/>
                        </button>
                    </v-col>
                </v-row>
            </v-container>
              <v-tabs
                v-model="tab"
                icons-and-text
                centered
                slider-size="0"
                active-class="step-active"
                >            
                <v-tab v-show="false" href="#survey"/>
                <v-tab v-show="false" href="#recommendations"/>
                <v-tab v-show="false" href="#summary"/>

              

                
                <v-tabs-items v-model="tab" style="margin-top: -80px;">
                  <div class="sub-title" style="font-weight: normal;" v-html="$store.getters.localization.toolSubTitle[_language]"/>

                  <v-tab-item data-test-tabItem="survey" :value="'survey'">
                    <v-form v-if="questionSet" ref="form" id="form" v-model="validSurvey">
                      <QuestionSet :language="_language" :questionSet="questionSet" v-model="answers"/>
                    </v-form>
                  </v-tab-item>

                  <v-tab-item data-test-tabItem="recommendations" :value="'recommendations'">
                    <Recommendations v-if="states.appliedAnswers" v-model="treatments" :language="_language" :recommendations="recommendations" :questionSet="questionSet" :kt="kt" :answers="answers" @treatmentSelection="treatmentSelection" @provinceSelection="provinceSelection"/>
                  </v-tab-item>

                  <v-tab-item data-test-tabItem="summary" :value="'summary'" v-if="tab=='summary'">
                    <Summary v-if="states.appliedAnswers" v-model="treatments" :language="_language" :recommendations="recommendations" :questionSet="questionSet" :kt="kt" :answers="answers" :debug="debug" @treatmentSelection="treatmentSelection" @provinceSelection="provinceSelection"/>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>

              <center style="margin-top: 25px;">
                <v-btn depressed style="margin: 25px;" @click="reset">
                      {{$store.getters.localization['reset'][_language]}}
                </v-btn>
                <template  v-if="_stepIndex<steps.length-1">
                  <v-btn :disabled="!steps[_stepIndex].complete" color="success" @click="selectStep(steps[_stepIndex+1].name)">
                    <template v-if="tab=='survey' && !steps[_stepIndex].complete">
                      {{$store.getters.localization['answer questions'][_language]}}
                    </template>
  
                    <template v-else>
                      {{$store.getters.localization['continue'][_language]}}
                    </template>
                  </v-btn>              
                </template>
              </center>
        </v-col>
      </v-row>

      <v-row style="padding: 0px; margin: 50px 0px 0px 0px;">
        <v-col align="center" style="padding:0px;">
            <v-progress-linear
                background-color="none"
                color=""
                :value="_progress"
                height="8"
            />
        </v-col>
      </v-row>
    </v-container>

    <Recommendations v-if="_qa" v-model="treatments" :language="_language" :recommendations="recommendations" :questionSet="questionSet" :kt="kt" :answers="answers" :qaMode="_qa" @treatmentSelection="treatmentSelection" @provinceSelection="provinceSelection"/>
  </div>
</template>

<script>
import QuestionSet from '@/components/QuestionSystem/QuestionSet';
import Survey from '@/components/Steps/Survey'
import Recommendations from '@/components/Steps/Recommendations'
import Summary from '@/components/Steps/Summary'
import Content from '@/lib/Content.js'

export default {
  name: 'DecisionTool',
  props: {
    Controller: {
      type: Object,
      required: true
    }
  },  
  components: {
    QuestionSet,
    Survey,
    Recommendations,
    Summary
  },
  async created(){
    this.apiUrl = process.env.VUE_APP_API
    let d = new Date()
    this.session = d.getTime()
    await this.getQuestionSet()
    this.applyAnswers()
    
    let stepName = this.$route.params.stepName
    if(stepName && stepName!=this.tab){
      this.tab=stepName
      this.states.from_link = stepName=='summary'
    }
  },
  data(){
    return {
      apiUrl: '/api',
      session: null,
      config: {
        source: 'file'
      },
      states: {
        appliedAnswers: false,
        from_link: false
      },
      timer: null,
      logTimer: null,
      questionSet: null,
      answers: {},
      recommendations: null,
      kt: null,
      tab: "survey",
      validSurvey: false,
      steps: [
        {
          name: "survey",
          icon: "mdi-account-box",
          complete: false
        },
        {
          name: "recommendations",
          icon: "mdi-book-open",
          complete: false
        },
        {
          name: "summary",
          icon: "mdi-playlist-check",
          complete: false
        }
      ],
      treatments: [],
      provinces: []
    }
  },
  methods: {
    async getQuestionSet(){
      let config = this.config
      let questionSetId = this.$route.meta.questionSetId
      let contentKey = this.$route.meta.localDb

      switch(config.source){
        case"api":
          let response = await this.Controller.QuestionSet.getQuestionSet(questionSetId)
          this.questionSet = response.data.questionSet
        break;

        case"file":
          const localDb = Content[contentKey]
          this.questionSet = localDb.questionSet
          this.recommendations = Content[contentKey].ktContent.recommendations
          this.kt = Content[contentKey].ktContent.kt   
        break;

        case"cache":
          let saved = this.cache.storage.local.get('ktSet')
          this.recommendations = saved[contentKey].recommendations
          this.kt = saved[contentKey].kt          
        break;
      }


      
      

      

      // this.recommendations = localDb.recommendations
      // this.kt = localDb.kt
    },
    selectStep(stepName){
      let answers = JSON.stringify(this.answers)
      answers = JSON.parse(answers)
      let query = {
        treatments: this.$route.query.treatments,
        provinces: this.$route.query.provinces
      }

      for(let qid in answers){
        for(let gid in answers[qid]){
          let value_key=`${qid}^${gid}`
          query[value_key] = answers[qid][gid].value
          

          for(let cid in answers[qid][gid].other){
            let other_key=`${qid}^${gid}~${cid}`
            query[other_key] = answers[qid][gid].other[cid]
          }
        }
      }
      this.tab = stepName
      this.$router.push({name:this.$route.meta.stepName,params:{stepName},query})
    },
    applyAnswers(){
      let ignoreKeys=['treatments','provinces']
      let query = this.$route.query
      for(let key in query){
        if(!ignoreKeys.includes(key)){
          let params = key.split('^')
          if(params.length>1){
            let answer = query[key]
            let qid = params[0]
            let g = params[1].split('~')
            let gid = g[0]
            let otherId = g[1]
            let question = this.questionSet.questions.find((item)=>{return item.id==qid})
            let group = question ? question.groups.find((item)=>{return item.id==gid}) : undefined
            
            if(answer!=null){
              if(otherId){
                this.answers[qid][gid].other[otherId] = answer
                
              }else{
                this.answers[qid][gid].value = ['checkbox'].includes(group.type) && typeof answer=='string' ? [answer] : answer
              }
            }
          }
        }

      }
      this.states.appliedAnswers = true
    },
    treatmentSelection(data){
      let query = this.$route.query
      query.treatments = data
      this.$router.push({name:this.$route.name,query})
    },
    provinceSelection(data){
      let query = this.$route.query
      query.provinces = data
      this.provinces = data
      this.$router.push({name:this.$route.name,query})
      this.logData()
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async logData(){
      async function sendLog(apiUrl, session, tool, from_link, data){
        let response = await self.sendRequest("post",`${apiUrl}/log_data`, {session, tool, from_link, origins: window.location.href, data})
        return response
      }
      let self = this
      let apiUrl = this.apiUrl
      clearTimeout(self.logTimer)
      self.logTimer = setTimeout(async () => {
        let triggerLog = {
          survey: null,
          recommendations: null,
          summary: null
        }
        for(let i=0; i<self.steps.length; i++){
          let step = self.steps[i]
          triggerLog[step.name] = step.complete
        }

        let data = {
          answers: this.answers,
          treatments: this.treatments,
          provinces: this.provinces
        }
        if(triggerLog.survey || triggerLog.recommendations){
          await sendLog(apiUrl, self.session, self.$route.meta.localDb, self.states.from_link, data)
        }
        
      }, 800);
    },
    reset(){
      this.$refs.form.reset(); 
      this.selectStep('survey'); 
      this.$router.push({name:'Home'}); 
      location.reload()
    }
  },
  computed: {
    debug(){
      return this.$store.getters.debug
    },
    _progress: function(){
        let completion = 0
        let steps = this.steps
        for(let i=0; i<steps.length; i++){
            let step = steps[i]
            if(step.complete){
              completion++
            }
        }

        return completion > 0 ? Math.round((completion/steps.length)*100) : 0
    },
    _stepIndex(){
      let steps = this.steps
      let tab = this.tab
      let index = steps.findIndex((step)=>{return step.name==tab})
      return index
    },
    _language(){
      return this.$store.getters.controller.Language.language
    },
    _qa(){
      return this.$store.getters.qa
    }
  },
  watch: {
    answers: {
      deep: true,
      handler(){
        clearTimeout(this.timer)
        let self = this
        self.treatments = []
        self.timer = setTimeout(() => {
          let isValid = true
          let incomplete = []
          let questions = self.questionSet ? self.questionSet.questions : []
          for(let i=0; i<questions.length; i++){
            let question = questions[i]
            if(question.display && question.required){
              if(!question.valid){
                isValid = false
                incomplete.push(question.id)
              }
            }
          }
  
          self.steps[0].complete = isValid
          if(isValid){
            self.logData()
          }
        }, 200);
      }
    },
    treatments(){
      this.steps[1].complete = this.treatments.length>0
      this.steps[2].complete = this.treatments.length>0
      this.logData()
    }
  }
}
</script>

<style lang="scss">
#mCRPC_tool .v-item-group.v-tabs-bar{
    display: none;
}

h1, h2{
  color: $primary;
}

.sub-title{
  text-align: left;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 25px;
  background-color: #E6FBFF;
  padding: 15px;
  color: black;
}
.sub-title ul{
  margin-top: 15px;
}

.v-tab{
    min-width: 200px !important;
    color: white !important;
    background-color: #261062;
    border: 3px solid white;
    overflow: visible;
}

.v-tab.step-complete{
    background-color: #38B448;
}

.step-tab{
    text-transform: uppercase;
    background-color: #261062;
    border: 3px solid #261062;
    color:  white;
    font-weight: 500;
    width: 100%;
    min-height: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s linear;
}

.step-tab.active{
    background-color: #00A8CB;
    border: 3px solid #00A8CB;
}

.step-tab.complete{
    background-color: #38B448;
    border: 3px solid #38B448;
    transition: background-color 0.2s linear;
}

.step-tab.disabled{
    background-color: grey;
    border: 3px solid grey;
    transition: background-color 0.2s linear;
}

.step-tab.disabled:hover{
    border: 3px solid grey;
}

.step-tab:hover{
    border: 3px solid #00A8CB;
}

.step-tab-content{
    padding: 5px;
    position: relative;
    z-index: 100;
}

.step-tab-carrot{
    display: none;
    position: absolute;
    left: 50%;
    width: 35px;
    height: 35px;
    transform: rotate(45deg) translateX(-60%);
    bottom: -20px;
    background-color: inherit;
}
.step-tab.active .step-tab-carrot{
    display: block;
}
</style>