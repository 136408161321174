<template>
  <div>
    <v-container>
        <v-row>
            <v-col>
                Logics
                <json-viewer :value="logics"/>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
    computed: {
        logics(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>