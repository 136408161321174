import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteBlocker: false,
    debug: false,
    qa: false,
    localization: {
      toolTitle: {
        "en-ca": "mCRPC Treatment Decision Support Tool",
        "fr-ca": "Outil d’aide à la décision quant au traitement du CPRCm ",
      },
      toolSubTitle: {
        "en-ca": `Consider diagnostic imaging (bone scan, CT, and PSMA-PET), as well as genetic testing if not previously performed. Please refer to the following CUA guidelines and corresponding tool card for specific genetic testing recommendations prior to continuing: 
          <ul>
          <li>
            <a href='https://www.cua.org/system/files/Guideline-Files/8588_EN.pdf' target='_blank'>2023 CUA Guideline - Genetic Testing in Prostate Cancer</a>
          </li>
          <li>
            <a href='https://www.cua.org/program/24950' target='_blank'>CUA Tool Card for Genetic Testing in Prostate Cancer</a>
          </li>
        </ul>`,
        "fr-ca": `Envisager des épreuves d’imagerie diagnostique (scintigraphie osseuse, TDM et TEP au PSMA), ainsi que des analyses génétiques si celles-ci n’ont pas été effectuées auparavant. Veuillez consulter le guide de pratique de l’AUC et l’outil correspondant pour obtenir des recommandations particulières sur les analyses génétiques avant de poursuivre : 
        <ul>
        <li>
        <a href='https://www.cua.org/system/files/Guideline-Files/8588_FR.pdf' target='_blank'>Guide de pratique 2023 de l’AUC – Dépistage génétique du cancer de la prostate</a>
        </li><li>
        <a href='https://www.cua.org/fr/program/24950' target='_blank'>Fiche de l’AUC sur les analyses génétiques dans le cancer de la prostate</a>
        </li>
        </ul>`
      },
      "footer": {
        "en-ca": "Development supported by an independent grant from Novartis<br>All rights reserved",
        "fr-ca": "Cet outil a été créé par l’AUC et rendu possible grâce à un financement d’Novartis<br>Tous droits réservés"
      },
      survey: {
        "en-ca": "Survey",
        "fr-ca": "Sondage"
      },
      recommendations: {
        "en-ca": "Recommendations",
        "fr-ca": "Recommandations"
      },
      "CUA Recommendations": {
        "en-ca": "CUA Recommendations",
        "fr-ca": "Recommandations de l’AUC"
      },
      "level": {
        "en-ca": "Level",
        "fr-ca": "Niveau"
      },
      "Strong": {
        "en-ca": "Strong",
        "fr-ca": "Fort"
      },
      "Weak": {
        "en-ca": "Weak",
        "fr-ca": "Faible"
      },
      "Expert": {
        "en-ca": "Expert Opinion",
        "fr-ca": "Avis d'expert"
      },
      summary: {
        "en-ca": "Summary",
        "fr-ca": "Résumé"
      },
      "answer questions": {
        "en-ca": "Please answer all questions",
        "fr-ca": "Veuillez répondre à toutes les questions"
      },
      "continue": {
        "en-ca": "Continue",
        "fr-ca": "Continuer"
      },
      "Treatment Option": {
        "en-ca": "Treatment Option",
        "fr-ca": "Option de traitement"
      },
      "Select Treatment": {
        "en-ca": "Select Treatment",
        "fr-ca": "Sélectionner le traitement"
      },
      "Select Province for DAL": {
        "en-ca": "Please select the province to display drug access information or visit our drug access listing site to see all the information.",
        "fr-ca": "Veuillez sélectionner la province pour afficher les informations sur l'accès aux médicaments ou visiter notre site de liste d'accès aux médicaments pour voir toutes les informations."
      },
      "Access the CUA DAL": {
        "en-ca": "ACCESS THE CUA DRUG LISTING PLATFORM",
        "fr-ca": "ACCÉDEZ À LA PLATEFORME DE LISTE DE MÉDICAMENTS DE L'AUC"
      },
      "reset": {
        "en-ca": "Reset",
        "fr-ca": "Réinitialiser"
      },
      "Decision Tool Summary": {
        "en-ca": "Decision Tool Summary",
        "fr-ca": "Résumé de l’outil d'aide à la décision"
      },
      "Decision Tool Summary.below": {
        "en-ca": "Below are the Canadian Urological Association treatment recommendations based on the patient information entered.",
        "fr-ca": "Vous trouverez ci-dessous les recommandations de traitement de l'Association canadienne d'urologie, formulées en fonction des renseignements fournis sur le patient."
      },
      "Drug not funded": {
        "en-ca": `<p>This drug is not currently funded in the selected province, or funding criteria is not publicly available. </p>
        <p>Please refer to our drug access listing website for information on other treatment options.</p>`,
        "fr-ca": `<p>Ce médicament n'est actuellement pas financé dans la province sélectionnée, ou les critères de financement ne sont pas accessibles au public.</p>
        <p>Veuillez consulter notre site Web de liste d’accès aux médicaments pour obtenir des informations sur d’autres options de traitement.</p>`
      },
      "Download": {
        "en-ca": "Download",
        "fr-ca": "Télécharger"
      },
      "Copy Link": {
        "en-ca": "Copy Link",
        "fr-ca": "Copier le lien"
      },
      "Funding": {
        "en-ca": "Funding",
        "fr-ca": "Financement"
      },
      "Formularies": {
        "en-ca": "Formularies",
        "fr-ca": "Formulaires"
      },
      "DAL col.Drug (Brand Name) Manufacturer": {
        "en-ca": "Drug (Brand Name) Manufacturer",
        "fr-ca": "Médicament (Marque) Fabricant"
      },
      "DAL col.Indication": {
        "en-ca": "Indication",
        "fr-ca": "Indication"
      },
      "DAL col.Strength, Route": {
        "en-ca": "Strength, Route",
        "fr-ca": "Dosage, Voie d'administration"
      },
      "DAL col.DIN": {
        "en-ca": "DIN",
        "fr-ca": "DIN"
      },
      "DAL col.Provincial Funding Eligibility Criteria": {
        "en-ca": "Provincial Funding Eligibility Criteria",
        "fr-ca": "Critères d'admissibilité au financement provincial"
      },
      "DAL col.References": {
        "en-ca": "References",
        "fr-ca": "Références"
      },
      "DAL col.Patient Assistance Programs": {
        "en-ca": "Patient Assistance Programs",
        "fr-ca": "Programmes d'assistance aux patient•e•s"
      }
    },
    controller: {
      Language: undefined,
      QuestionSet: undefined
      // Label: undefined,
      // Search: undefined,
    }
  },
  getters: {
    siteBlocker: function(state){
      return state.siteBlocker
    },
    controller(state){
      return state.controller
    },
    debug(state){
      return state.debug
    },
    Label: function(state){
      return state.controller.Label
    },
    Search: function(state){
      return state.controller.Search
    },
    Language: function(state){
      return state.controller.Language
    },
    QuestionSet: function(state){
      return state.controller.QuestionSet
    },
    localization: function(state){
      return state.localization
    },
    qa: function(state){
      return state.qa
    }
  },
  mutations: {
    debug: function(state, payload){
      state.debug = payload
    },
    UserController: function(state, payload){
      state.controller.UserC = payload
    },
    LabelController: function(state, payload){
      state.controller.Label = payload
    },
    SearchController: function(state, payload){
      state.controller.SearchC = payload
    },
    LanguageController: function(state, payload){
      state.controller.Language = payload
    },
    QuestionSetController: function(state, payload){
      state.controller.QuestionSet = payload
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload;
    },
    qa: function(state, payload){
      state.qa = payload
    }
  },
  actions: {
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },      
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    LabelController: function(context, payload){
      context.commit('LabelController',payload)
    },
    SearchController: function(context, payload){
      context.commit('SearchController',payload)
    },
    LanguageController: function(context, payload){
      context.commit('LanguageController',payload)
    },
    QuestionSetController: function(context, payload){
      context.commit('QuestionSetController',payload)
    },
    qa: function(context, payload){
      context.commit('qa',payload)
    }
  },
  modules: {
  }
})
