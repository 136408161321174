<template>
  <div style="position: fixed; bottom:5px; left: 5px; z-index: 100;">

    <v-navigation-drawer
      v-show="drawer"
      v-model="drawer"
      src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      temporary
      dark
      width="600"
      height="800"
      >
      <v-list-item v-show="allow(['admin','staff','tester','client'])">
        <v-list-item-content>
          <v-btn dark small :to="{name:'questionAdmin'}">Question Admin</v-btn>
          <hr>

          <v-btn dark small :to="{name:'ktAdmin'}">KT Admin</v-btn>
          <hr>

          <v-btn dark small :to="{name:'dataView',query:{toolId:'bc'}}">Data View</v-btn>
        </v-list-item-content>
        <v-list-item-action>
        </v-list-item-action>
      </v-list-item>

      <template v-if="allow(['admin','staff','tester'])">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Debug</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="allow(['admin','staff','tester'])" color="white" x-small fab @click="toggleDebug">
              <v-icon :color="$store.getters.debug ? 'green' : 'black'">
                {{$store.getters.debug ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline'}}
              </v-icon>
            </v-btn>        
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>QA KT</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>     
            <v-btn v-if="allow(['admin','staff','tester'])" color="white" x-small fab @click="toggleQa">
              <v-icon :color="$store.getters.qa ? 'green' : 'black'">
                {{$store.getters.qa ? 'mdi-toggle-switch-outline' : 'mdi-toggle-switch-off-outline'}}
              </v-icon>
            </v-btn>          
          </v-list-item-action>
        </v-list-item>

      </template>


      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>LanguageController</v-list-item-title>
              <LanguageController/>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="allow(['admin','staff','tester'])">
            <v-list-item-icon>
              <v-icon>mdi-controller-classic-outline</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>QuestionSetController</v-list-item-title>
              <QuestionSetController/>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-btn v-if="allowMenuButton" color="teal" dark fab x-small @click="drawer=!drawer">
      <v-icon>
        mdi-menu
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import LanguageController from './LanguageController.vue';
import QuestionSetController from './QuestionSetController.vue';
// import SearchController from './SearchController.vue';
// import LabelController from './LabelController.vue';
export default {
    created(){
      if(process.env.VUE_APP_ENV=='local'){
        this.allowMenuButton = true
      }
    },
    components: {
      LanguageController,
      QuestionSetController,
      // SearchController,
      // LabelController,
    },
    data(){
      return {
        drawer: false,
        allowMenuButton: false
      }
    },
    methods: {
      toggleDebug(){
        this.$store.commit('debug' , !this.$store.getters.debug)
      },
      toggleQa(){
        this.$store.commit('qa' , !this.$store.getters.qa)
      },
      allow(groups){
        // return this.$store.getters.UserController && this.$store.getters.UserController.authorized(groups)
        return true
      }
    }
}
</script>

<style>

</style>